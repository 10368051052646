<template>
  <div>
    <Breadcrumbs main="" title="User Info" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-row class="">
            <b-col md="6" class="col-md-3 col-12">
              <b-input-group >
                <b-input-group-prepend >
                  <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                </b-input-group-prepend>
                <b-form-input
                  class="col-md-5"
                  v-model="filter"
                  placeholder="Type to Search"
                ></b-form-input>
              </b-input-group>
            </b-col>

            <b-col md="6" class="col-md-3 col-12">
              <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                <b-form-select v-model="perPage" :options="pageOptions" @change="filterData()"></b-form-select>
              </b-form-group>
            </b-col>

          </b-row>

          <b-row class="col-12">
            <b-col class="col-md-3 col-12 mt-2 d-flex align-items-center">
              <b-form-group label="Sort" class="w-100">
                <div class="d-flex">
                  <b-form-select id="sort-by-select" v-model="sortBy" :options="sortOptions" class="mr-1"
                    style="font-size: 0.85rem; width: 70%;">
                    <template #first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select v-model="sortDesc" :disabled="!sortBy" @input="filterData"
                    style="font-size: 0.85rem; width: 30%;">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </div>
              </b-form-group>
            </b-col>

            <b-col class="col-md-3 col-12 d-flex align-items-end">
              <b-form-group label="Country" class="w-100">
                <multiselect :options="countryOpt" label="name" v-model="selectedCountry" placeholder="Select Country"
                  :multiple="false" :close-on-select="true" class="w-100" style="font-size: 0.75rem; color: black;" />
              </b-form-group>
            </b-col>

            <b-col class="col-md-4 col-12 d-flex align-items-end">
              <b-form-group label="Date Range">
                <date-range-picker 
                  style="display: inline; font-size: 0.80rem;" 
                  ref="picker" 
                  opens="center"
                  :locale-data="{ firstDay: 1, 
                  format: 'dd-mm-yyyy' }"
                  :singleDatePicker="false"
                  :timePicker="false" 
                  :timePicker24Hour="true" 
                  :showWeekNumbers="false" 
                  :showDropdowns="true"
                  :autoApply="true" 
                  v-model="dateRange" 
                  @update="ChangedDate" 
                  :linkedCalendars="false"
                  :max-date="new Date()">
                  <template v-slot:input="picker">
                    <span style="font-size: 0.85rem;">
                    {{ changeDatePickerFormate(dateRange.startDate).format("D/M/YYYY") }} - {{
                      changeDatePickerFormate(dateRange.endDate).format("D/M/YYYY") }}
                    </span>
                  </template>
                </date-range-picker>
              </b-form-group>
            </b-col>

            <b-col class="col-md-2 col-12 d-flex align-items-center">
              <b-button @click="filterData" variant="primary" class="mr-2">Filter</b-button>
              <b-button @click="init" variant="primary">Reset</b-button>
            </b-col>
          </b-row>




          <div class="col-md-12" v-if="loading">
            <h6 class="sub-title mb-0 text-center">Loading...</h6>
            <div class="loader-box">
              <div class="loader-30"></div>
            </div>
          </div>
          <div v-else class="table-responsive datatable-vue text-left">
            <b-table show-empty stacked="md" :items="items" :filter="filter" @filtered="onFiltered"
              :fields="tablefields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
              <template #table-caption>User List.</template>

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(email)="data">
                <b class="">{{ data.item.email }}</b>
              </template>

              <template #cell(signupDateTime)="data">
                <b class="font-weight-normal">{{ changeDateFormate(data.item.signupDateTime) }}</b>
              </template>

              <template #cell(lastLoginDate)="data">
                <b class="font-weight-normal">{{ changeDateFormate(data.item.lastLoginDate) }}</b>
              </template>

              <template #cell(acquisitionChannel)="data">
                <b class="res_text1 font-weight-normal">{{ data.item.acquisitionChannel }}</b>
              </template>

              <template #cell(activeMonitors)="data">
                <b class="font-weight-normal">{{ data.item.activeMonitors }}</b>
              </template>

              <template #cell(country)="data">
                <b class="font-weight-normal">{{ data.item.country }}</b>
              </template>

              <template #cell(pricePageVisitCount)="data">
                <b class="font-weight-normal"> {{ data.item.pricePageVisitCount }} </b>
              </template>

              <template #cell(signupCompletion)="data">
                <b class="font-weight-normal"> {{ data.item.signupCompletion ? 'Yes' : 'No' }} </b>
              </template>

              <template #cell(planName)="data">
                <b class="font-weight-normal"> {{ data.item.planName }} </b>
              </template>

              <template #cell(lastTransaction)="data">
                <b class="font-weight-normal"> {{ changeDateFormate(data.item.lastTransaction) }} </b>
              </template>

              <template #cell(nextTransaction)="data">
                <b :class="checkExpiry(data.item.nextTransaction)">
                  {{ changeDateFormate(data.item.nextTransaction) }}
                </b>
              </template>
            </b-table>
          </div>

          <b-col md="6" class="mt-3 p-0">
            <div class="pagination">
              <a v-show="currentPage != 1" @click="filterData('prev')">&laquo; Back</a>
              <a class="active">{{ currentPage }}</a>
              <a v-show="showNextButton" @click="filterData('next')">Next &raquo;</a>
            </div>
          </b-col>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import UserController from "../../../services/userController"
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
const today = new Date();
const oneMonthAgo = new Date(today);
oneMonthAgo.setMonth(today.getMonth() - 1);

export default {
  name: 'Users',
  components: {
    DateRangePicker,
    Multiselect
  },
  data() {
    return {
      allocatePlan: false,
      input: { plan_id: null, country: null, startDate: null, endDate: null },
      items: [],
      totalRows: 0,
      filter: null,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 50],
      loading: false,
      tablefields: ['index',
        { key: "email", label: "Email", sort: true },
        { key: 'country', label: 'Country' },
        { key: 'acquisitionChannel', label: 'Referer' },
        { key: "signupCompletion", label: "Sign-up Completion" },
        { key: 'activeMonitors', label: 'Active Monitors', sort: true },
        { key: 'pricePageVisitCount', label: 'Plan Page Visit' },
        { key: 'planName', label: 'Plan Name' },
        { key: "signupDateTime", label: "Sign-up   Date", sort: true },
        { key: "lastLoginDate", label: "Last Login", sort: true },
        { key: "lastTransaction", label: "Last Transaction" },
        { key: "nextTransaction", label: "Plan   Expiry", sort: true }
      ],
      userinfo: [],
      user_data: [],
      sortBy: "signupDateTime",
      sortDesc: true,
      sortDirection: "desc",
      countryOpt: [{ "value": null, "name": "All Countries" }],
      dateRange: {
        startDate: oneMonthAgo,
        endDate: today
      },
      selectedCountry: null
    }
  },
  mounted() {
    this.userinfo = JSON.parse(window.localStorage.getItem('userinfo'))
    this.getCountries()
    this.ChangedDate()
    this.filterData()
  },
  watch: {
    monitor_id() {
      this.currentPage = 1
      this.filterData()
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter((f) => f.sort)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    showNextButton() {
      return this.items.length >= this.perPage;
    },
  },
  methods: {
    async getCountries() {
      let response = await UserController.getCountryList();
      if (response.result) {
        this.countryOpt = [{ value: null, name: "All Countries" }, ...response.message.map(country => ({ value: country, name: country }))];
      }
    },
    async filterData(txt) {
      this.loading = true;
      if (txt == 'next') { this.currentPage++ }
      if (txt == 'prev') {
        if (this.currentPage != 1) {
          this.currentPage--
        }
      }

      let query = {
        currentPage: this.currentPage || 1,
        pageSize: this.perPage || 10,
        sortBy: this.sortBy || "signupDateTime",
        order: this.sortDesc ? 'desc' : 'asc'
      }

      let payload = {
        country: this.selectedCountry ? this.selectedCountry.value : "",
        sdate: this.input.startDate,
        edate: this.input.endDate
      }

      this.loading = true;
      let response = await UserController.getUserInfo(query, payload);
      if (response.result) {
        this.items = response.message;
        this.totalRows = this.items.length;
      } else {
        this.$toasted.show(response.message, { theme: "outline", position: "bottom-center", type: "error", duration: 2000 });
      }
      this.loading = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ChangedDate() {
      let sdate = new Date(this.dateRange.startDate).toISOString();
      let edate = new Date(this.dateRange.endDate).toISOString();
      this.input.startDate = sdate.substring(0, 10) + 'T00:00:00.000Z';
      this.input.endDate = edate.substring(0, 10) + 'T23:59:59.000Z';
    },
    changeDateFormate(d) {
      if (d) {
        return moment(d).format("D/M/YYYY, h A");
      }
    },
    changeDatePickerFormate(d) {
        return moment(d);
    },
    checkExpiry(nextTransaction) {
      const currentDate = new Date();
      const transactionDate = new Date(nextTransaction);
      return transactionDate < currentDate ? 'text-danger font-weight-normal' : '';
    },
    init() {
      this.selectedCountry = null;
      this.dateRange = {
        startDate: oneMonthAgo,
        endDate: today
      };
      this.sortBy = "signupDateTime",
      this.sortDesc = true,
      this.ChangedDate()
      this.filterData();
    }
  }
}
</script>

<style>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  cursor: pointer;
}

.table-responsive .b-table thead th {
  vertical-align: middle;

}

.res_text1 {
  display: inline-block;
  max-width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  cursor: pointer;
}

.res_text1:hover {
  max-width: auto;
  text-overflow: clip;
  white-space: normal;
}
</style>